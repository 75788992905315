body {
  text-align: center;
}

.light-mode {
  background-color: white;
  color: black;
}

.dark-mode {
  background-color: black;
  color: white;
}


h1 {
  font-family: 'Cute Font', sans-serif;
  font-size: 100px;
  font-weight: 400;
  font-style: normal;
}

h4 {
  font-family: 'Cute Font', sans-serif;
  font-size: 30px;
  font-weight: 400;
  font-style: normal;
  margin-top: 200px;
}

.content {
  font-family: "Inknut Antiqua", serif;
  display: block;
  font-size: 16px;
  max-width: 400px;
  margin: 0 auto;
  margin-top: -60px;
}

.content button {
  margin: 0 10px;
  padding: 10px 20px;
  font-size: 18px;
  border: none;
  border-radius: 5px;
  cursor: pointer;
}

.reduced-top-margin {
  margin-top: 10px; /* Adjust this value as needed */
}

.content button:hover {
  opacity: 0.8;
}

.iconBar {
  display: block;
  margin: 0 auto;
  margin-top: 50px;
}

#modeChange {
  max-width: 50px;
}

#github, #linkedin, #pdf{
  max-width: 50px;
}

#github:hover, #linkedin:hover, #pdf:hover {
  opacity: 0.7;
}

a {
  text-decoration: none;
}

.navigate-button {
  position: fixed;
  top: 10px; /* adjust as needed */
  right: 10px; /* adjust as needed */
}
.nanalan-img {
  width: 300px; /* adjust as needed */
  height: auto;
}

@keyframes spin {
  0% { transform: rotate(0deg); }
  100% { transform: rotate(360deg); }
}

.spinning-heart {
  display: inline-block;
  font-size: 50px; /* adjust as needed */
  color: red; /* adjust as needed */
  animation: spin 2s linear infinite;
}

.clear {
  clear: both;
}

.image-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.image-container img {
  width: 300px;
  margin-top: 20px;
}

@media (max-width: 600px) {
  .navigate-button {
    margin-bottom: 20px; /* add space below the button */
  }

  .AwesomeButton {
    font-size: 14px; /* make the button text smaller */
  }

  .content {
    font-size: 14px; /* make the content text smaller */
    padding: 0 20px; /* add padding on the left and right */
  }
}

.typing-effect {
    overflow: hidden;
    border-right: .15em solid #ff69b4;
    white-space: nowrap;
    animation: typing 3.5s steps(40, end), blink-caret .75s step-end infinite;
    margin-bottom: 2px;
}

@keyframes typing {
    from { width: 0 }
    to { width: 100% }
}

@keyframes blink-caret {
    from, to { border-color: transparent }
    50% { border-color: #ff69b4; }
}

.heart-container {
    text-align: center;
    margin: 20px 0;
}

.heart-icon {
    transition: all 0.3s ease;
}

.heart-pulse {
    animation: pulse 1s infinite;
}

@keyframes pulse {
    0% { transform: scale(1); }
    50% { transform: scale(1.2); }
    100% { transform: scale(1); }
}

.image-bounce {
    animation: bounce 0.5s infinite;
}

@keyframes bounce {
    0%, 100% { transform: translateY(0); }
    50% { transform: translateY(-10px); }
}

.sparkle-text {
    background: linear-gradient(45deg, #ff69b4, #ff1493, #ff69b4);
    -webkit-background-clip: text;
    -webkit-text-fill-color: transparent;
    animation: sparkle 2s infinite;
}

.fade-in {
    opacity: 0;
    animation: fadeIn 2s forwards;
}

.typing-effect {
  color: #FF69B4;
  text-shadow: 2px 2px 4px rgba(255, 105, 180, 0.2);
}

.content {
  background: linear-gradient(135deg, rgba(255,255,255,0.9) 0%, rgba(255,192,203,0.1) 100%);
  padding: 20px;
  border-radius: 20px;
  box-shadow: 0 4px 6px rgba(255, 182, 193, 0.1);
}

@keyframes fadeIn {
    to { opacity: 1; }
}